import React, { ReactElement } from 'react';
import classes from './styles/imageTileOnPLP.module.scss';

interface ISetImageTileOnPLP {
    productList?: Array<ReactElement>;
    storeCode: string;
    tileImageSrc?: string;
}

export const setImageTileOnPLP = ({
    productList,
    storeCode,
    tileImageSrc,
}: ISetImageTileOnPLP): Array<ReactElement> | undefined => {
    if (!tileImageSrc || !productList || productList.length < 8) return productList;

    const productArrayWithImage = [...productList];
    productArrayWithImage.splice(
        2,
        0,
        <div className={classes.root} key="badge">
            <a href={'/' + storeCode + '/skifree'} className={classes.link}>
                <img className={classes.img} loading="lazy" src={tileImageSrc} alt="Ski-free" />
            </a>
        </div>,
    );

    return productArrayWithImage;
};
