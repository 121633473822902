'use client';

import React, { useContext, useMemo } from 'react';
import { useTranslations } from 'next-intl';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';
import { Grid, Button2 as Button } from '@vaimo-int/aem-pwa-common-components';
import ProductCard from '../ProductCard';
import Spinner from '@/components/Spinner';
import Pagination from '@/components/Pagination';
import ProductsGridSkeleton from './modules/Skeleton';
import { useProductsGrid } from './hooks/useProductsGrid';
import { useYotpo } from '@/integrations/yotpo';
import type { IProductsGrid } from './types';
import { setImageTileOnPLP } from '@/integrations/optimizely/src/migration/ImageTileOnPLP/setImageTileOnPLP';
import { useRequestContext } from '@/lib/context';
import classes from './productsGrid.module.scss';

const ProductsGrid = ({ isBot, pageSize, tileImageSrc }: IProductsGrid) => {
    const {
        breadcrumbs,
        categoryId,
        categoryUrlPath,
        fhrId,
        filterType,
        isCategoryPage,
        isPending,
        items,
        name,
        pageInfo,
        search,
        setProductsData,
        totalCount,
    } = useContext(CategoryContext);
    const { storeCode } = useRequestContext();
    const t = useTranslations('categoryContent');
    const {
        cachedFirstLoadPage,
        changePagination,
        handlePaginationBack,
        handlePaginationForward,
        loading,
        nextFetchProducts,
        pageControl,
        products,
        setInViewport,
        showBackButton,
        showNextButton,
    } = useProductsGrid({
        breadcrumbs,
        categoryId,
        currentPage: pageInfo.current_page,
        fhrId,
        filterType,
        initialItems: items,
        isCategoryPage,
        name,
        pageSize,
        search,
        setProductsData,
        totalCount,
        totalPages: pageInfo.total_pages,
    });

    const productIds = useMemo(() => (nextFetchProducts || items).map(({ id }) => id) || [], [nextFetchProducts]);

    const { getYotpoRating } = useYotpo({
        currentProductId: categoryId || null,
        productIds,
    });

    const galleryItems = useMemo(
        () =>
            products.map((item, index: number) => {
                const itemIndex = pageInfo.current_page > 1 ? index + pageSize * (cachedFirstLoadPage - 1) : index;

                return (
                    <ProductCard
                        changePagination={changePagination}
                        fhrId={fhrId}
                        index={itemIndex}
                        isBot={isBot}
                        item={item}
                        key={itemIndex}
                        lastProduct={itemIndex + 1 === totalCount}
                        pageSize={pageSize}
                        setInViewport={setInViewport}
                        yotpoRating={getYotpoRating(item.id)}
                    />
                );
            }),
        [products, search, getYotpoRating],
    );

    return (
        <div className={classes.root}>
            {isPending ? (
                <ProductsGridSkeleton length={galleryItems?.length} pageSize={pageSize} />
            ) : (
                <>
                    {showBackButton && (
                        <div className={classes.loaderWrapper} data-mt-type="product-listing-show-previous">
                            <Button size="small" variant={'outlined'} onClick={() => handlePaginationBack()}>
                                {t.rich('loadPreviousX', {
                                    previousProductsCount: () => pageSize,
                                })}
                            </Button>
                        </div>
                    )}
                    <Grid.Row>{setImageTileOnPLP({ productList: galleryItems, storeCode, tileImageSrc })}</Grid.Row>
                    {showNextButton && (
                        <div className={classes.loaderWrapper} data-mt-type="product-listing-show-next">
                            {loading ? (
                                <Spinner />
                            ) : (
                                <Button size="small" variant={'outlined'} onClick={() => handlePaginationForward()}>
                                    {t.rich('loadNextX', {
                                        nextProductsCount: () =>
                                            pageInfo.current_page === pageInfo.total_pages - 1
                                                ? totalCount % pageSize
                                                : pageSize,
                                    })}
                                </Button>
                            )}
                        </div>
                    )}
                    <Pagination
                        classes={classes.pagination}
                        urlPath={categoryUrlPath}
                        isFirstPageExcluded
                        pageControl={pageControl}
                    />
                </>
            )}
        </div>
    );
};

export default ProductsGrid;
